import { isValidCron } from 'cron-validator';
import { isNil } from 'lodash';

import {
  isEmail,
  isISO8601,
  isJSON,
  isLength,
  isURL,
} from 'validator';

// Validation helpers
const createValidation = (validator, errorMessage) => (
  (...args) => validator(...args) || errorMessage
);

const isNumber = (val) => !Number.isNaN(Number(val));

const hasValue = (val) => (Array.isArray(val)
  ? val.length > 0
  : !isNil(val) && String(val).length > 0
);

// Validator wrappers
export const validEmail = createValidation(
  (str) => !str || isEmail(str),
  'Por favor, digite um email válido',
);

export const validUrl = createValidation(
  (str) => !str || isURL(str),
  'Por favor, digite uma URL válida',
);

export const validJSON = createValidation(
  (str) => !str || isJSON(str),
  'Por favor, digite um JSON válido',
);

export const validDate = createValidation(
  (str) => !str || isISO8601(str, { strict: true }),
  'Digite uma data válida (YYYY-MM-DD)',
);

export const cronExpression = createValidation(
  (str) => !str || isValidCron(str),
  'Por favor, digite uma expresão de cron válida',
);

// Custom rules
export const requiredField = createValidation(
  (val) => hasValue(val),
  'Campo obrigatório',
);

export const requiredIf = (fn) => createValidation(
  (val) => !fn() || hasValue(val),
  'Campo obrigatório',
);

export const fieldMaxLength = (max) => createValidation(
  (str) => isLength(str ?? '', { max }),
  `O campo deve ter até ${max} caracteres`,
);

export const fieldMinLength = (min) => createValidation(
  (str) => isLength(str ?? '', { min }),
  `O campo deve ter pelo menos ${min} caracteres`,
);

export const fieldRangeLength = (min, max) => createValidation(
  (str) => isLength(str ?? '', { min, max }),
  `O campo deve ter entre ${min} e ${max} caracteres`,
);

export const minDateLimit = (minDateStr) => createValidation(
  (str) => !str || !minDateStr || str >= minDateStr,
  `A data deve ser igual ou posterior a ${minDateStr}`,
);

export const validNumber = createValidation(
  (str) => !str || isNumber(str),
  'Por favor, digite um número válido',
);
